import React, { useEffect, useState } from 'react';
import { Card, CardBody, Button } from "@material-tailwind/react";
import { _getAllPrompts, _updateAllPrompts } from 'Services/api';

const ApplicableFlow = () => {
    const [data, setData] = useState(null);
    const [updatedItems, setUpdatedItems] = useState([]); // Track changed items

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('VendorToken'); 
                const response = await _getAllPrompts({ accessToken });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (category, subcategory, uniqueId) => {
        setData(prevData => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                [subcategory]: prevData[category][subcategory].map(item =>
                    item.unique_id === uniqueId
                        ? { ...item, is_checked: !item.is_checked }
                        : item
                )
            }
        }));

        // Track updated items in the updatedItems state
        setUpdatedItems(prevItems => {
            const updatedIndex = prevItems.findIndex(item => item.unique_id === uniqueId);
            const newItem = { unique_id: uniqueId, category, subcategory };

            if (updatedIndex > -1) {
                return prevItems.map((item, index) => (index === updatedIndex ? newItem : item));
            }
            return [...prevItems, newItem];
        });
    };

    const handleUpdatePrompts = async () => {
        const accessToken = sessionStorage.getItem('VendorToken');
        try {
            for (const item of updatedItems) {
                const { unique_id, category, subcategory } = item;
                // Find the updated item's is_checked status in the data
                const isChecked = data[category][subcategory].find(i => i.unique_id === unique_id).is_checked;

                const payload = {
                    vendor_org_type: category, // Add other necessary fields as per your API requirements
                    vendor_org_type_status: isChecked
                };

                // Call the API with unique_id as a parameter and payload as the body
                await _updateAllPrompts({
                    body: payload,
                    accessToken,
                    prompt_id: unique_id
                });
            }

            console.log("All selected prompts have been updated successfully.");
            setUpdatedItems([]); // Clear the updatedItems after saving
        } catch (error) {
            console.error("Error updating prompts:", error);
        }
    };

    const renderCheckboxes = (categoryData, categoryName) => (
        <div className="container mx-auto p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {Object.keys(categoryData).map(subcategory => (
                    <div key={subcategory}>
                        <div><b>{subcategory.replace(/_/g, ' ')}</b></div>
                        <ul>
                            {categoryData[subcategory].map(item => (
                                <li key={item.unique_id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={item.is_checked}
                                            onChange={() => handleCheckboxChange(categoryName, subcategory, item.unique_id)}
                                        /> {item.name}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

    if (!data) return <div>Loading...</div>;

    return (
        <div>
            {Object.keys(data).map((categoryKey) => (
                <Card key={categoryKey} className="h-full w-full overflow-scroll mb-6">
                    <CardBody className="overflow-scroll p-5">
                        <h2 className='mb-2'><b>{categoryKey.replace(/_/g, ' ')}</b></h2>
                        <hr className='mb-3' />
                        {renderCheckboxes(data[categoryKey], categoryKey)}
                        <hr className='mb-4' />
                        <div className='text-right'>
                            <Button color="blue" onClick={handleUpdatePrompts}>Save</Button>
                        </div>
                    </CardBody>
                </Card>
            ))}
        </div>
    );
};

export default ApplicableFlow;
