import React, { useEffect, useState } from 'react';
import { Card, CardBody } from "@material-tailwind/react";
import { _getLanguage, _toggleLanguage } from 'Services/api';
import { toast } from 'react-toastify';

const Language = () => {
  const [fetchedLanguages, setFetchedLanguages] = useState([]);
  const [languageError, setLanguageError] = useState("");

  useEffect(() => {
    const fetchSupportedLanguages = async () => {
      try {
        const token = sessionStorage.getItem("VendorToken");
        const response = await _getLanguage({ accessToken: token });
        if (response.status) {
          // Set languages with checked status directly from the API response
          const languagesWithCheckedStatus = response.data.map(language => ({
            ...language,
            is_checked: language.is_checked || false // Use API value or default to false
          }));
          setFetchedLanguages(languagesWithCheckedStatus);
        }
      } catch (error) {
        console.log("Error fetching languages:", error);
      }
    };

    fetchSupportedLanguages();
  }, []);

  const handleLanguageClick = (selectedLanguage) => {
    setFetchedLanguages((prevLanguages) =>
      prevLanguages.map((language) =>
        language.code === selectedLanguage.code
          ? { ...language, is_checked: !language.is_checked } // Toggle is_checked
          : language
      )
    );
    setLanguageError("");
  };

  const handleUpdateBotSupportLang = async () => {
    try {
      const vendorToken = sessionStorage.getItem("VendorToken");
      const languages = fetchedLanguages
        .filter(language => language.is_checked) // Only active languages
        .map(language => ({
          language_id: language.language_id,
          is_active: true
        }));

      const body = { languages };
      const response = await _toggleLanguage(body, vendorToken);

      if (response.status) {
        toast.success("Languages updated successfully");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="flex justify-center mt-4">
      <Card className="w-full max-w-4xl overflow-hidden mb-6">
        <CardBody className="p-5">
          <h2 className="mb-4 text-lg font-semibold">Languages</h2>
          <hr className="mb-4" />
          <div className="overflow-y-auto max-h-80">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {fetchedLanguages.map((language) => (
                <div key={language.code} className="flex items-center">
                  <input
                    type="checkbox"
                    id={language.code}
                    className="mr-2"
                    checked={language.is_checked}
                    onChange={() => handleLanguageClick(language)}
                  />
                  <label
                    htmlFor={language.code}
                    className="text-gray-700"
                  >
                    {language.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr className="my-4" />
          <div className="text-right">
            <button
              className="rounded-md bg-blue-500 py-2 px-4 text-white"
              onClick={handleUpdateBotSupportLang}
            >
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Language;
