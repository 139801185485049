import React from "react";

// Admin Imports
// import BotList from "views/admin/botList";
import UserProfile from "views/admin/userProfile"
import Help from "views/admin/help";
import CustomizeMessage from 'views/admin/customizeMessage'
import NFTMarketplace from "views/admin/marketplace";
import Vendor from "views/admin/vendor";
import ApplicableFlow from "views/admin/applicableFlow";
// import Profile from "views/admin/profile";
// import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdLock,
} from "react-icons/md";
import Login from "views/auth/Login";
import Organization from "views/auth/Organization";
import { layout } from "@chakra-ui/system";
import { comment } from "postcss";
import Language from "views/admin/language";

const routes = [
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   component: <UserProfile />,
  // },
  {
    name: "Customer Support",
    layout: "/admin",
    path: "help",
    component: <Help />,
  },
  {
    name: "Customize Message",
    layout: "/admin",
    path: "customize-message",
    component: <CustomizeMessage/>,
  },
  {
    name: "Vendor",
    layout: "/admin",
    path: "/vendor",
    component: <Vendor />,
  },
  {
    name: "Applicable Flow",
    layout: "/admin",
    path: "/applicable-flow",
    component: <ApplicableFlow />,
  },
  {
    name: "Languages",
    layout: "/admin",
    path: "/languages",
    component: <Language />,
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: ":id", // Updated
    icon: <MdHome className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <Login />,
  },
  {
    name: "Organization",
    layout: "/auth",
    path: "organization",
    icon: <MdLock className="h-6 w-6" />,
    component: <Organization />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
