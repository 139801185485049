// Accordion.js
import React from 'react';

const Accordion = ({ title, children, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-20">
      <button
        onClick={onClick}
        className="w-full text-left flex justify-between items-center p-4 focus:outline-none bg-gray hover:bg-gray-200"
      >
        <span className="font-medium">{title}</span>
        <span>{isOpen ? '-' : '+'}</span>
      </button>
      {/* Conditional rendering for the accordion content */}
      {isOpen && (
        <div className="p-4 bg-white">
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
