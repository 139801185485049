import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import { Switch } from "@material-tailwind/react";
import {
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Button,
  Typography,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { _deactiveVendor } from "Services/api";
import { toast } from "react-toastify";

function VendorTable({
  columns,
  data,
  loading,
  pageInfo,
  onNextPage,
  onPrevPage,
}) {
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("VendorToken");
  const [deactivatedRows, setDeactivatedRows] = useState([]);

  const [isOn, setIsOn] = useState(false);

  const toggle = () => setIsOn(!isOn);

  const handleStatusToggle = async (user_id, isActive) => {
    try {
      const response = await _deactiveVendor({ user_id, accessToken });
      if (response.status) {
        toast.success(response.message);
        setDeactivatedRows((prev) =>
          isActive ? prev.filter((id) => id !== user_id) : [...prev, user_id]
        );
      } else {
        toast.error(response.message);
      }
      console.log("Vendor status toggled:", response);
    } catch (error) {
      console.error("Error toggling vendor status:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Typography variant="h6" color="blue-gray">
          Loading...
        </Typography>
      </div>
    );
  }

  return (
    <Card className="h-full w-full overflow-scroll">
      <CardBody className="overflow-scroll px-0 pt-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead className="border-b">
            <tr>
              {columns.map((column) => (
                <th key={column} className="p-4">
                  <Typography
                    variant="small"
                    className="font-bold leading-none"
                  >
                    {column}
                  </Typography>
                </th>
              ))}
              <th className="p-4">
                <Typography variant="small" className="font-bold leading-none">
                  Status
                </Typography>
              </th>
              <th className="p-4">
                <Typography variant="small" className="font-bold leading-none">
                  Actions
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((user, rowIndex) => {
              const isDeactivated =
                deactivatedRows.includes(user.user_id) || !user.is_active;
              return (
                <tr
                  key={user.user_id || rowIndex}
                  className={`p-4 ${
                    isDeactivated ? "bg-gray-200 text-gray-400" : ""
                  }`}
                >
                  <td className="p-4">
                    <Typography variant="small" className="font-normal">
                      {user.first_name}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" className="font-normal">
                      {user.last_name}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" className="font-normal">
                      {user.email}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" className="font-normal">
                      {user.phone_number}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" className="font-normal">
                      {isDeactivated ? "Inactive" : "Active"}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <div className="flex items-center gap-2">
                      <IconButton
                        variant="text"
                        size="sm"
                        className="align-center flex justify-center"
                        onClick={() => navigate(`/admin/${user.user_id}`)}
                      >
                        <MdEdit className="h-4 w-4 text-gray-900" />
                      </IconButton>
                      <div
                        onClick={() => handleStatusToggle(user.user_id)}
                        className={`flex h-6 w-12 cursor-pointer items-center rounded-full p-1 transition-all ${
                          isDeactivated ? "bg-gray-300" : "bg-green-500"
                        }`}
                      >
                        <div
                          className={`h-4 w-4 transform rounded-full bg-white shadow-md transition-transform ${
                            isDeactivated ? "translate-x-0" : "translate-x-6"
                          }`}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>

      <CardFooter className="border-blue-gray-50 flex items-center justify-between border-t p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {pageInfo.page} of {pageInfo.totalPages}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            size="sm"
            onClick={onPrevPage}
            disabled={pageInfo.page === 1}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={onNextPage}
            disabled={pageInfo.page === pageInfo.totalPages}
          >
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

export default VendorTable;
