import { useEffect, useRef, useState, useCallback } from 'react';

const useWebSocket = (url) => {
  const [connectionStatus, setConnectionStatus] = useState('');
  const [socketMessage, setSocketMessage] = useState(''); // State to hold the latest incoming message
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log('WebSocket connection established');
      setConnectionStatus('connected');
    };

    ws.onmessage = (event) => {
      console.log('Received message:', event.data);
      setSocketMessage(JSON.parse(event.data)); // Update socketMessage state
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      setConnectionStatus('disconnected');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setConnectionStatus('error');
    };

    setSocket(ws);

    // Cleanup function to close the WebSocket connection
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [url]);

  const sendMessage = useCallback(
    (message) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: "message", ...message }));
      }
    },
    [socket]
  );

  return { connectionStatus, sendMessage, socketMessage }; // Return socketMessage state
};

export default useWebSocket;
