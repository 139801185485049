import React, { createContext, useContext } from 'react';
import useWebSocket from 'hooks/useWebSocket';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children, url }) => {
  const socket = useWebSocket(url); // Use the provided URL

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};