// UserTable.js
import React, { useState } from "react";
import { FaEye, FaComments } from "react-icons/fa"; // Import eye icon
import UserDetailModal from "./UserDetailModal"; // Import the modal

const formatFlowType = (flowType) => {
  return flowType
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const UserTable = ({ users, onUserSelect }) => {
  const [selectedUser, setSelectedUser] = useState(null); // State to manage the selected user

  const handleOpenModal = (user) => {
    setSelectedUser(user);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full border border-gray-200 bg-white">
        <thead>
          <tr className="w-full bg-gray-100 text-sm uppercase leading-normal text-gray-600">
            <th className="py-3 px-6 text-left">Name</th>
            <th className="py-3 px-6 text-left">Email</th>
            <th className="py-3 px-6 text-left">Type</th>
            <th className="py-3 px-6 text-left">Action</th>
          </tr>
        </thead>
        <tbody className="text-sm font-light text-gray-600">
          {users.map((user) => (
            <tr
              key={user.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="py-3 px-6">{user.name}</td>
              <td className="py-3 px-6">{user.email}</td>
              <td className="py-3 px-6">{formatFlowType(user.flowType)}</td>
              <td className="py-3 px-6 flex justify-center space-x-2">
                {user.flowType === "chat_escalation" && (
                  <div className="relative">
                    <button
                      onClick={() => onUserSelect(user)}
                      className="flex items-center justify-center text-gray-600 hover:text-gray-900"
                    >
                      <FaComments className="h-5 w-5" />
                    </button>
                    {!user.is_read && (
                      <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500"></span>
                    )}
                  </div>
                )}
                <button onClick={() => handleOpenModal(user)} className="text-gray-600 hover:text-gray-900">
                  <FaEye />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Render the modal */}
      {selectedUser && (
        <UserDetailModal user={selectedUser} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default UserTable;
