/* eslint-disable */
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { MdEdit, MdOutlineSupportAgent, MdHome } from "react-icons/md";
import { PiUserList } from "react-icons/pi";
import { layout } from "@chakra-ui/system";
import NFTMarketplace from "views/admin/marketplace";
import { ProfileContext } from "context/ProfileContext";
import { TiFlowChildren } from "react-icons/ti";
import { IoLanguageSharp } from "react-icons/io5";


// chakra imports

export function SidebarLinks(props) {
  const profileData = useContext(ProfileContext);
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  
  const isAdmin = localStorage.getItem('isAdmin')
  

  // Add static routes
  const dashboardRoute = [
  ];

  if (isAdmin !== 'true') {
    dashboardRoute.push(
      {
        name: "Dashboard",
        layout: "/admin",
        path: profileData?.user_id, // Updated
        icon: <MdHome className="h-6 w-6" />,
        component: <NFTMarketplace />,
        secondary: true,
      },
      {
        name: "Help",
        layout: "/admin",
        path: "help",
        icon: <MdOutlineSupportAgent /> 
      },
      {
        name: "Customize Message",
        layout: "/admin",
        path: "customize-message",
        icon: <MdEdit/>
      }
    )
  }
  // Conditionally add the 'Vendor' route if isAdmin is true
  if (isAdmin === 'true') {
    console.log("isAdmin", isAdmin)
    dashboardRoute.push(
      {
        name: "Vendor",
        layout: "/admin",
        path: "vendor",
        icon: <PiUserList />
      },
      {
        name: "Applicable Flow",
        layout: "/admin",
        path: "applicable-flow",
        icon: <TiFlowChildren />
      },

{
        name: "Language",
        layout: "/admin",
        path: "languages",
        icon: <IoLanguageSharp />
      },
    );
  }

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white w-SS"
                      : "font-medium text-gray-600 w-SS"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white w-SS"
                      : "font-medium text-gray-600 w-SS"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(dashboardRoute);
}

export default SidebarLinks;
