import React, { useState, useEffect } from 'react';
import { LuWorkflow } from "react-icons/lu";
import { _getApplicablePromptDetails, _updatePromptDetails } from '../../../../Services/api';
import { useParams } from 'react-router-dom';

function PromptGeneration({ botId }) {
  const [prompts, setPrompts] = useState([]);
  const [flows, setFlows] = useState([]);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [selectedFlows, setSelectedFlows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false); // New state for save button loading
  const [message, setMessage] = useState(null);
  const { id: userId } = useParams();

  // Get accessToken from localStorage
  const accessToken = sessionStorage.getItem('VendorToken');

  useEffect(() => {
    const fetchPromptDetails = async () => {
      if (!botId) return;
      setLoading(true);
      try {
        const response = await _getApplicablePromptDetails({ accessToken, botId, user_id: userId });
        if (response.status) {
          const promptsData = response.data.prompts || [];
          const flowsData = response.data.flows || [];

          setPrompts(promptsData);
          setFlows(flowsData);

          // Initialize selected prompts and flows based on is_marked
          const initialSelectedPrompts = promptsData
            .filter((prompt) => prompt.is_marked)
            .map((prompt) => prompt.prompt_id);
          const initialSelectedFlows = flowsData
            .filter((flow) => flow.is_marked)
            .map((flow) => flow.prompt_id);

          setSelectedPrompts(initialSelectedPrompts);
          setSelectedFlows(initialSelectedFlows);
        } else {
          setMessage({
            type: 'error',
            text: response.message || 'Failed to fetch prompt details.',
          });
        }
      } catch (error) {
        console.error('Error fetching prompt details:', error);
        setMessage({
          type: 'error',
          text: 'An error occurred while fetching prompt details.',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPromptDetails();
  }, [botId, accessToken]);

  const handlePromptChange = (promptId) => {
    setSelectedPrompts((prevSelected) =>
      prevSelected.includes(promptId)
        ? prevSelected.filter((id) => id !== promptId)
        : [...prevSelected, promptId]
    );
  };

  const handleFlowChange = (flowId) => {
    setSelectedFlows((prevSelected) =>
      prevSelected.includes(flowId)
        ? prevSelected.filter((id) => id !== flowId)
        : [...prevSelected, flowId]
    );
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    setMessage(null);
    try {
      const applicable_prompts = prompts.map((prompt) => ({
        is_marked: selectedPrompts.includes(prompt.prompt_id),
        prompt_id: prompt.prompt_id,
        prompt_name: prompt.prompt_name,
        prompt_key: prompt.prompt_key,
      }));

      const applicable_flows = flows.map((flow) => ({
        is_marked: selectedFlows.includes(flow.prompt_id),
        prompt_id: flow.prompt_id,
        prompt_name: flow.prompt_name,
        prompt_key: flow.prompt_key,
      }));

      const payload = {
        applicable_prompts,
        applicable_flows,
      };

      const response = await _updatePromptDetails({
        body: payload,
        accessToken,
        botId,
        user_id: userId
      });

      if (response.status) {
        setMessage({ type: 'success', text: 'Prompt details updated successfully.' });
      } else {
        setMessage({
          type: 'error',
          text: response.message || 'Failed to update prompt details.',
        });
      }
    } catch (error) {
      console.error('Error updating prompt details:', error);
      setMessage({
        type: 'error',
        text: 'An error occurred while updating prompt details.',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="mt-4 rounded-md bg-white p-4 shadow">
      <h1 className="text-xl text-[#666]">
        <b className="align-center flex gap-2">
          <LuWorkflow /> Applicable Prompts & Flows
        </b>
      </h1>
      <hr className='my-3' />

      {/* Message Display */}
      {message && (
        <div
          className={`mt-4 p-2 rounded-md ${
            message.type === 'success'
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}
        >
          {message.text}
        </div>
      )}

      {loading ? (
        <div className="mt-4">Loading...</div>
      ) : (
        <>
          {/* Applicable Prompts */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              Applicable Prompts:
            </label>
            {prompts.length > 0 ? (
              <ul className="mt-2">
                {prompts.map((prompt) => (
                  <li key={prompt.prompt_id} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`prompt-${prompt.prompt_id}`}
                      checked={selectedPrompts.includes(prompt.prompt_id)}
                      onChange={() => handlePromptChange(prompt.prompt_id)}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                    <label
                      htmlFor={`prompt-${prompt.prompt_id}`}
                      className="ml-2 text-gray-700"
                    >
                      {prompt.prompt_name}
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-2 text-gray-500">No prompts available.</p>
            )}
          </div>

          {/* Applicable Flows */}
          {/* <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              Applicable Flows:
            </label>
            {flows.length > 0 ? (
              <ul className="mt-2">
                {flows.map((flow) => (
                  <li key={flow.prompt_id} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`flow-${flow.prompt_id}`}
                      checked={selectedFlows.includes(flow.prompt_id)}
                      onChange={() => handleFlowChange(flow.prompt_id)}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                    <label
                      htmlFor={`flow-${flow.prompt_id}`}
                      className="ml-2 text-gray-700"
                    >
                      {flow.prompt_name}
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-2 text-gray-500">No flows available.</p>
            )}
          </div> */}

          {/* Save Changes Button */}
          <button
            onClick={handleSaveChanges}
            disabled={saving}
            className={`mt-6 inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              saving
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
            }`}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </button>
        </>
      )}
    </div>
  );
}

export default PromptGeneration;