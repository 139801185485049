import React, { useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import {
  _apiKnowledgeSource,
  _getApiKnowledgeSource,
  _getPaymentGateways,
  _linkBotOwner,
  _updateApiKnowledgeSource,
} from "../../../../Services/api"; // Import the API function
import AddSubaccountModal from "./AddSubaccountModal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function ApiKnowledgeSource({ botId, setIsKnowledgeApi }) {
  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [knowledgeId, setKnowledgeId] = useState("");
  const accessToken = sessionStorage.getItem("VendorToken");
  const [isPaymentRequired, setIsPaymentRequired] = useState();
  const [paymentUrl, setPaymentUrl] = useState("");
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [isSubaccountModalOpen, setIsSubaccountModalOpen] = useState(false);
  const { id: userId } = useParams();
  const validateEmails = (emailList) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailList.every((email) => emailRegex.test(email));
  };

  useEffect(() => {
    const fetchApiKnowledgeSource = async () => {
      try {
        setLoading(true);
        const response = await _getApiKnowledgeSource({ botId, accessToken, user_id: userId });
        
          setIsKnowledgeApi(true);
        setApiEndpoint(response.data.base_api_endpoint);
        setApiKey(response.data.default_api_key);
        setEmails(
          response.data.owner_details?.map((owner) => owner.ownerName) || []
        );
        setKnowledgeId(response.data.api_knowledge_id);
        if (response.data.is_payment_required) {
          setIsPaymentRequired("yes"); // Set to "yes" if true
        } else {
          setIsPaymentRequired("no"); // Set to "no" if false
        }
      } catch (error) {
        console.error("Error fetching API knowledge source:", error);
        setMessage({
          type: "error",
          text: "Failed to load API knowledge source.",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchApiKnowledgeSource();
  }, []);

  const handleLinkOwner = async () => {
    const emailList = emails.split(", ").map((email) => email.trim());

    if (!validateEmails(emailList)) {
      setMessage({
        type: "error",
        text: "One or more email addresses are invalid.",
      });
      return;
    }

    const payload = {
      bot_id: botId,
      emails: emailList,
    };

    try {
      setLoading(true);
      setMessage(null);
      const response = await _linkBotOwner({
        body: payload,
        accessToken: accessToken,
      });
      if(response.status) {
// toast.success(response)
        setMessage({ type: "success", text: "Owners linked successfully!" });
      } else {
        toast.error(response.message);
      }
      console.log("Success:", response);
    } catch (error) {
      console.error("Error linking owners:", error);
      toast.error(error.response.data.message)
      setMessage({
        type: "error",
        text: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleApiDefaultsSetup = async (payload) => {
    try {
      setLoading(true);
      setMessage(null);
      // Call the _setApiDefaults API function
      const response = await _apiKnowledgeSource({
        body: payload,
        accessToken: accessToken,
        user_id: userId,
      });
      console.log("API Defaults Setup Success:", response);
      setMessage({ type: "success", text: "API defaults set successfully!" });
    } catch (error) {
      console.error("Error setting API defaults:", error);
      setMessage({
        type: "error",
        text: "An error occurred while setting API defaults.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateApiKnowledge = async (payload) => {
    try {
      setLoading(true);
      setMessage(null);
      const response = await _updateApiKnowledgeSource({
        body: payload,
        accessToken,
        botId,
        knowledge_id: knowledgeId,
        user_id: userId
      });
      console.log("API Knowledge Source Updated:", response);
      setMessage({
        type: "success",
        text: "API endpoint and key updated successfully!",
      });
    } catch (error) {
      console.error("Error updating API knowledge source:", error);
      setMessage({
        type: "error",
        text: "Failed to update API knowledge source.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const basePayload = {
      bot_id: botId,
      base_api_endpoint: apiEndpoint,
      default_api_key: apiKey,
      is_payment_required: isPaymentRequired,
    };
    if (isPaymentRequired) {
      basePayload.payment_gateway_id = selectedPaymentGateway;
    } else {
      basePayload.redirect_website_url =
        paymentUrl || "https://example.com/redirect";
    }
    console.log("object", knowledgeId)
    if (knowledgeId) {
      handleUpdateApiKnowledge(basePayload); 
    } else {
      handleApiDefaultsSetup(basePayload); 
    }
  };

  const handleCategoryListAndPaymentGateways = async () => {
    try {
      const token = sessionStorage.getItem("VendorToken");
      const payment_gateways_response = await _getPaymentGateways(token);

      if (payment_gateways_response.status) {
        setPaymentGateways(payment_gateways_response?.data?.payment_gateways);
        setSelectedPaymentGateway(
          payment_gateways_response?.data?.payment_gateways[0]
            ?.payment_gateway_id
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCategoryListAndPaymentGateways();
  }, []);
  const handlePaymentRequirementChange = (e) => {
    setIsPaymentRequired(e.target.value === "yes");
  };

  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
    setPaymentMethodError(""); // Clear payment method error when a gateway is selected
  };

  const openSubaccountModal = () => setIsSubaccountModalOpen(true);
  const closeSubaccountModal = () => setIsSubaccountModalOpen(false);

  return (
    <div className="mt-4 rounded-md bg-white p-4 shadow">
      {/* Header */}
      <h1 className="text-xl text-[#666]">
        <b className="align-center flex gap-2">
          <FaUserEdit /> API Knowledge Source
        </b>
      </h1>

      {/* Message Display */}
      {message && (
        <div
          className={`mt-4 rounded-md p-2 ${
            message.type === "success"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {message.text}
        </div>
      )}
      <div className="mt-4">
        <h2 className="text-lg font-semibold">API Defaults Setup</h2>

        {/* Default Base API Endpoint Input */}
        <div className="mt-4">
          <label className="text-black-800 block font-bold">
            Default Base API Endpoint:
          </label>
          <input
            type="text"
            value={apiEndpoint}
            onChange={(e) => setApiEndpoint(e.target.value)}
            className="mt-1 w-full rounded border p-2"
            placeholder="Specify the default API endpoint used by the bot for fetching data."
          />
        </div>

        {/* Default API Key Input */}
        <div className="mt-4">
          <label className="text-black-800 block font-bold">
            Default API Key:
          </label>
          <input
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="mt-1 w-full rounded border p-2"
            placeholder="Set the default authentication key to access external APIs."
          />
        </div>
        <h2 className="mt-4 text-xl font-bold text-gray-800">
          Payment Section
        </h2>

        <div className="flex flex-col space-y-2">
          <label className="font-medium text-gray-700">
            Is payment required?
          </label>
          <select
            onChange={handlePaymentRequirementChange}
            value={isPaymentRequired ? "yes" : "no"}
            className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </select>
        </div>

        {isPaymentRequired ? (
          <div className="space-y-3">
            <h3 className="text-lg font-semibold text-gray-800">
              Select Payment Gateway
            </h3>
            <div className="space-y-2">
              {paymentGateways.map((gateway) => (
                <label
                  key={gateway.payment_gateway_id}
                  htmlFor={gateway.payment_gateway_id}
                  className="block"
                >
                  <input
                    type="radio"
                    id={gateway.payment_gateway_id}
                    name="payment-method"
                    value={gateway.payment_gateway_id} // using payment_gateway_id as value
                    checked={
                      selectedPaymentGateway === gateway.payment_gateway_id
                    }
                    onChange={handlePaymentGatewayChange}
                    style={{ marginRight: "5px" }}
                  />
                  {gateway.name.charAt(0).toUpperCase() + gateway.name.slice(1)}{" "}
                  {/* Display gateway name */}
                </label>
              ))}
            </div>

            <button
              onClick={openSubaccountModal}
              className="text-blue underline"
            >
              Add Subaccount
            </button>

            <AddSubaccountModal
              isOpen={isSubaccountModalOpen}
              onClose={closeSubaccountModal}
              onSave={(subaccountData) => {
                console.log("Subaccount Data:", subaccountData);
                closeSubaccountModal();
                // Additional logic to handle saved subaccount data
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col space-y-2">
            <label className="font-medium text-gray-700">
              Enter Payment URL:
            </label>
            <input
              type="text"
              value={paymentUrl}
              onChange={(e) => setPaymentUrl(e.target.value)}
              placeholder="Enter your payment URL here"
              className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}
        <button
          onClick={handleSubmit}
          disabled={loading}
          className={`border-transparent mt-4 inline-flex items-center rounded-md border px-4 py-2 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            loading
              ? "cursor-not-allowed bg-gray-400"
              : "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
          }`}
        >
          {loading ? "Setting Defaults..." : "Set API Defaults"}
        </button>
      </div>
      {/* Emails Input */}
      <div className="mt-4">
        <label className="text-black-800 block font-bold">
          Enter Email Addresses (comma-separated):
        </label>
        <input
          type="text"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          className="mt-1 w-full rounded border p-2"
          placeholder="e.g., user1@example.com, user2@example.com"
        />
      </div>

      {/* Link Owner Button */}
      <button
        onClick={handleLinkOwner}
        disabled={loading}
        className={`border-transparent mt-4 inline-flex items-center rounded-md border px-4 py-2 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          loading
            ? "cursor-not-allowed bg-gray-400"
            : "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
        }`}
      >
        {loading ? "Linking..." : "Link Owner"}
      </button>
    </div>
  );
}

export default ApiKnowledgeSource;
