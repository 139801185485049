import React, { useState } from 'react';


const AccordionTable = ({ data, prompt_id, onUpdateStep }) => {
    const [editIndex, setEditIndex] = useState(null); // Track which step is being edited
    const [editedContent, setEditedContent] = useState(''); // Track the new value of the edited content
  
    // Function to handle the click of the Edit button
    const handleEditClick = (index, currentContent) => {
      setEditIndex(index); // Set the current index to edit
      setEditedContent(currentContent); // Set the current step content as the initial value
    };
  
    // Function to handle when the user saves the updated step content
    const handleSaveClick = (item) => {
      // Pass the updated content to the parent component
      onUpdateStep({
        ...item,
        step_content: editedContent,
        step_id: item.step_order, 
        prompt_id,   // Include prompt_id from props
      });
      setEditIndex(null); // Exit edit mode
    };
  
    return (
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 border border-gray-300">Step Order</th>
            <th className="px-4 py-2 border border-gray-300">Step Content</th>
            <th className="px-4 py-2 border border-gray-300">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.step_order}>
              <td className="px-4 py-2 border border-gray-300">{item.step_order}</td>
  
              {/* Check if the current row is in edit mode */}
              <td className="px-4 py-2 border border-gray-300">
                {editIndex === index ? (
                  // Show input field if in edit mode
                  <input
                    type="text"
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    className="border rounded px-2 w-full py-1"
                  />
                ) : (
                  // Show the step content if not in edit mode
                  item.step_content
                )}
              </td>
  
              <td className="px-4 py-2 border border-gray-300">
                {editIndex === index ? (
                  // Show Save button when in edit mode
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => handleSaveClick(item)}
                  >
                    Save
                  </button>
                ) : (
                  // Show Edit button if not in edit mode
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => handleEditClick(index, item.step_content)}
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  export default AccordionTable;
