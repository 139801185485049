import axios from 'axios';

export const sendRequest = async ({ url, method = 'GET', body = null, headers = { "Content-Type": "application/json" } }) => {
    try {
        const options = {
            url,
            method,
            headers
        };

        if (body) {
            options.data = body;
        }

        const response = await axios(options);
        return response.data;
    } catch (error) {
        console.error('Error sendRequest:', error);
        throw error;
    }
};
