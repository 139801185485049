import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { IoSend } from 'react-icons/io5';
import { ProfileContext } from "context/ProfileContext";
import { useWebSocketContext } from "context/WebSocketContext"; // Import the WebSocket context

const ChatWindow = ({ chatId, user, messages, onAddMessage }) => {
  const [message, setMessage] = useState('');
  const chatEndRef = useRef(null);
  const profileData = useContext(ProfileContext); // Access profile data
  const { sendMessage, socketMessage } = useWebSocketContext(); // Get sendMessage and socketMessage from context

  const handleIncomingMessage = useCallback((data) => {
    console.log('Received message:', data);
    onAddMessage({ text: data, sender: "Bot" });
  }, [onAddMessage]);

  // Effect to handle incoming socket messages
  useEffect(() => {
    if (socketMessage) {
      handleIncomingMessage(socketMessage); // Call the handler when socketMessage changes
    }
  }, [socketMessage, handleIncomingMessage]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = () => {
    if (message.trim()) {
      onAddMessage({ text: message, sender: "You" });
      setMessage('');
      const data = {
        client_id: profileData.user_id,
        message: message,
        escalation_chat_id: chatId,
      };
      sendMessage(data);
    }
  };

  return (
    <div className="w-full flex flex-col">
      {user ? (
        <div className="flex flex-col h-full">
          <div className="text-xl font-semibold p-4 border-b">{user.name}</div>
          <div className="flex-1 overflow-y-auto p-4">
          {messages.map((msg, index) => (
              <div key={index} className={`mb-2 ${msg.sender === 'You' ? 'text-right' : 'text-left'}`}>
                <div className={`inline-block px-4 py-2 rounded-lg ${msg.sender === 'You' ? 'bg-blue-500 text-white text-sm' : 'bg-gray-100 text-sm'}`}>
                  {msg.text}
                </div>
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>
          <div className="mt-auto flex chatInputBox">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              className="w-full p-2 border rounded-lg"
              placeholder="Type a message..."
            />
            <button
              onClick={handleSendMessage}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg sendBtn"
            >
              <IoSend />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">Select a user to start chatting</p>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;