import InputField from "components/fields/InputField";
import { useState } from "react";
import { _vendorLogin } from "Services/api";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // State for loading
  const [apiResError, setApiResError] = useState();
  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;
    if (!email) {
      tempErrors["email"] = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors["email"] = "Email is invalid";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleVendorLogin = async () => {
    if (handleValidation()) {
      setLoading(true); // Set loading to true before API request
      try {
        const body = {
          email: email,
          password: password,
        };
        const response = await _vendorLogin({ body });
        if (response.status) {
          console.log("vendor", response);
          sessionStorage.setItem("VendorToken", response?.data?.access_token);
          const isAdmin = response?.data?.type?.includes("ADMIN") ? true : false;
          localStorage.setItem("isAdmin", isAdmin);
          if (response?.data?.type?.includes("ADMIN")) {
            window.location.href = `/admin/vendor`
          } else {
            window.location.href = `/admin/${response.data.user_id}`;
          }
        }
      } catch (error) {
        console.log(error);
        setApiResError(error?.response?.data?.message)
      } finally {
        setLoading(false); // Set loading to false after API request
      }
    }
  };

  return (
    <div className="relative mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Loader Overlay */}
      {loading && (
        <div className="loader-overlay">
          <span className="loader"></span>
        </div>
      )}

      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <div className="text-red-600">{apiResError}</div>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@mail.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <span className="error">{errors.email}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div> */}
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button
          onClick={handleVendorLogin}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          disabled={loading} // Disable button while loading
        >
          Sign In
        </button>
        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
        
        <style jsx>{`
          .loader-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999; /* Ensure it's on top of other content */
          }

          .loader {
            border: 8px solid rgba(0, 0, 0, 0.1); /* Light grey background */
            border-radius: 50%;
            border-top: 8px solid white; /* Blue color for the spinner */
            width: 50px;
            height: 50px;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    </div>
  );
}
