import endpoints from "./endpoint";
import { sendRequest } from "./sendRequest";

export const _vendorRegister = async ({ body }) => {
  const url = endpoints.vendorRegister();
  try {
    const response = await sendRequest({ url, method: "POST", body });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _createOrganization = async ({ body }) => {
  const url = endpoints.createOrganization();
  try {
    const response = await sendRequest({ url, method: "POST", body });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _vendorLogin = async ({ body }) => {
  const url = endpoints.vendorLogin();
  try {
    const response = await sendRequest({ url, method: "POST", body });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _vendorCategories = async () => {
  const url = endpoints.vendorCategories();
  try {
    const response = await sendRequest({ url, method: "GET" });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _botCreation = async ({ body, accessToken }) => {
  const url = endpoints.botCreation();
  try {
    const response = await sendRequest({
      url,
      method: "POST",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getLanguage = async ({ accessToken }) => {
  const url = endpoints.getLanguage();
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getActiveLanguage = async ({ accessToken }) => {
  const url = endpoints.activeLanguage();
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _languageUpdate = async ({ body, accessToken }) => {
  const url = endpoints.languageUpdate();
  try {
    const response = await sendRequest({
      url,
      method: "PATCH",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _toggleLanguage = async (body, accessToken ) => {
  const url = endpoints.toggleLanguage();
  try {
    const response = await sendRequest({
      url,
      method: "POST",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _userProfile = async (accessToken) => {
  const url = endpoints.userProfile();
  console.log("accessToken: ", accessToken);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const _organizationDetails = async (accessToken) => {
  const url = endpoints.getOrganizationDetails();
  console.log("accessToken: ", accessToken);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getPaymentGateways = async (token) => {
  const url = endpoints.getPaymentGateways();
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateBotKnowledgeDetails = async ({
  botId,
  file,
  url,
  accessToken,
}) => {
  const form = new FormData();

  if (file) {
    form.append("knowledge_source_file", file);
  }

  if (url) {
    form.append("url", url);
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const urlWithParams = `${endpoints.updateBotKnowledgeDetails()}?bot_id=${botId}`;

  try {
    const response = await sendRequest({
      url: urlWithParams,
      method: "PATCH",
      body: form,
      headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _update_bot = async (botId, deatilsToBeUpdated, accessToken) => {
  try {
    const updateBotUrl = `${endpoints.updateBot(botId)}`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await sendRequest({
      url: updateBotUrl,
      method: "PATCH",
      body: { ...deatilsToBeUpdated },
      headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getBotDetails = async ({ accessToken, userId }) => {
  const url = endpoints.getBot(userId);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getUserList = async ({ accessToken, page = 1, limit = 10 }) => {
  const url = endpoints.getUsersList(page, limit);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _linkBotOwner = async ({ body, accessToken }) => {
  const url = endpoints.linkBotOwner();
  try {
    const response = await sendRequest({
      url,
      method: "POST",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getPromptDetails = async ({ accessToken, botId }) => {
  const url = endpoints.getPromptDetails(botId);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getApplicablePromptDetails = async ({ accessToken, botId, user_id }) => {
  const url = endpoints.getApplicablePromptDetails(botId, user_id);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _apiKnowledgeSource = async ({ body, accessToken, user_id }) => {
  const url = endpoints.apiKnowledgeSource(user_id);
  try {
    const response = await sendRequest({
      url,
      method: "POST",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updatePromptDetails = async ({ body, accessToken, botId, user_id }) => {
  const url = endpoints.updatePromptDetails(botId, user_id);
  try {
    const response = await sendRequest({
      url,
      method: "PATCH",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _deactiveVendor = async ({ user_id, accessToken }) => {
  const url = endpoints.deactivateVendor(user_id);
  try {
    const response = await sendRequest({
        url,
      user_id,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const _updatePromptSteps = async ({
  prompt_id,
  step_id,
  body,
  accessToken,
}) => {
  const url = endpoints.updatePrompt(prompt_id, step_id); // Ensure that endpoint correctly builds the URL
  try {
    const response = await sendRequest({
      url,
      method: "PUT",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getEscalationRequests = async ({
  bot_id,
  page,
  limit,
  is_read,
  is_resolved,
  room_no,
  accessToken,
}) => {
  const url = endpoints.getEscalationRequests(
    bot_id,
    page,
    limit,
    is_read,
    is_resolved,
    room_no
  );
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateEscalationRequest = async ({escalationId, payload, accessToken}) => {
  try {
    const url = `${endpoints.patchEscalationRequests(escalationId)}`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await sendRequest({
      url: url,
      method: "PATCH",
      body: { ...payload },
      headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getEscalationHistory = async ({ accessToken, escalationId }) => {
  const url = endpoints.getEscalationHistory(escalationId);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const _createSubaccount = async ({ body, accessToken }) => {
  const url = endpoints.createSubaccount();
  try {
    const response = await sendRequest({
      url,
      method: "POST",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getApiKnowledgeSource = async ({ accessToken, botId, user_id }) => {
  const url = endpoints.getApiKnowledgeSource(botId, user_id);
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateApiKnowledgeSource = async ({ body, accessToken, botId, knowledge_id, user_id }) => {
  const url = endpoints.updateApiKnowledgeSource(botId, knowledge_id, user_id);
  try {
    const response = await sendRequest({
      url,
      method: "PATCH",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getAllPrompts = async ({ accessToken }) => {
  const url = endpoints.getAllPrompts();
  try {
    const response = await sendRequest({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateAllPrompts = async ({ body, accessToken, prompt_id }) => {
  const url = endpoints.updateAllPrompts(prompt_id);
  try {
    const response = await sendRequest({
      url,
      method: "PATCH",
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};