import React, { useState } from "react";
import { _updateBotKnowledgeDetails } from "Services/api";

const KnowledgeUpdateForm = ({ botId }) => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setError("Only PDF files are allowed.");
      setFile(null);
    } else {
      setError("");
      setFile(selectedFile);
    }
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSubmit = async () => {
    setError("");
    setSuccess("");
    // check whether the bot is created or not
    if(!botId) {
      setError("Please create a bot first.");
      return;
    }
    
    if (!file && !url) {
      setError("Please select a file and enter a URL both before submitting.");
      return;
    }
    console.log("file", file);
    console.log("url", url);
    
    try {
      console.log("botId", botId);
      setLoading(true);

      const accessToken = sessionStorage.getItem("VendorToken");

      const response = await _updateBotKnowledgeDetails({
        botId,
        file,
        url,
        accessToken,
      });
      if (!response.status) {
        setError(response.message);
        return;
      }
      console.log("here", response);
      setFile(null);
      setUrl("");

      setSuccess("Bot knowledge updated successfully!");
    } catch (err) {
      console.log("bot knowledge", err)
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="knowledge">
        <div className="upload-container">
        <p style={{ fontSize: "14px", color: "gray", marginBottom: "5px" }}>
            Please upload only PDF files.
          </p>
          <input
            type="file"
            className="upload-input"
            accept=".pdf"
            onChange={handleFileChange}
          />
        </div>

        <input
          type="text"
          className="text-input"
          placeholder="Enter the URL"
          value={url}
          onChange={handleUrlChange}
        />
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>

      </div>
        {success && <p style={{ color: "green", textAlign:'left', fontSize:'14px', marginTop: '5px' }}>{success}</p>}
        {error && <p style={{ color: "red", textAlign:'left', fontSize:'14px', marginTop: '5px' }}>{error}</p>}
    </div>
  );
};

export default KnowledgeUpdateForm;
